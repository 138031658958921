<template>
  <div id="placeEdit">
    <app-menu></app-menu>
    <div class="col-lg-10 offset-lg-1">
      <h3 class="text-center">Místo</h3>
      <div class="row pt-lg-4">
        <div class="col-lg-6">
          <h4 class="text-center">Čeština</h4>
          <form>
            <div class="form-group">
              <label for="formNameCz">Název:</label>
              <input type="text" class="form-control" id="formNameCz" v-model="stage.name.cs"
                     placeholder="Název místa">
            </div>
            <div class="form-group">
              <label for="TextareaCz">Popis(Lokality):</label>
              <textarea class="form-control" id="TextareaCz" v-model="stage.description.cs" rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="formNoteCz">Adresa:</label>
              <input type="text" class="form-control" id="formNoteCz" v-model="stage.note.cs" placeholder="Poznámka">
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <h4 class="text-center">Angličtina</h4>
          <form>
            <div class="form-group">
              <label for="formNameEn">Název:</label>
              <input type="text" class="form-control" id="formNameEn" v-model="stage.name.en"
                     placeholder="Název místa">
            </div>
            <div class="form-group">
              <label for="TextareaEn">Popis(Lokality):</label>
              <textarea class="form-control" id="TextareaEn" v-model="stage.description.en" rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="formNoteEn">Adresa:</label>
              <input type="text" class="form-control" id="formNoteEn" v-model="stage.note.en" placeholder="Poznámka">
            </div>
            <div class="form-check form-switch text-center pt-lg-4">
              <input id="visible" class="form-check-input" type="checkbox" v-model="stage.visible">
              <label class="form-check-label" for="visible">Viditelný v seznamu lokalit</label>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <p>Fotografii vkládajte při poměru stran 4:3</p>
          <img class="img-fluid" width="50%" :src="image"/>
          <input type="file" class="form-control-file" accept="image/jpeg" @change="selectImage"
                 aria-describedby="fileHelp">
          <small id="fileHelp" class="form-text text-muted"></small>
        </div>
      </div>

      <div class="text-center pt-lg-4">
        <send-button
            :send="create"
            text="Uložit Místo"
            :response="error"
        />
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import axios from "@/api/axiom-instance";
import AdminMenu from "@/components/admin/AdminMenu";
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";
import config from "@/config";

export default {
  name: "programPlaceEdit",
  components: {
    'app-menu': AdminMenu,
    'error-modal': ErrorModal,
    'send-button': SendButton
  },
  data() {
    return {
      stage: {name: {}, preview: {}, description: {}, note: {}},
      error: null,
      image: ""
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    create: function () {
      axios.post("/stage", this.stage).then((success) => {
        axios.post("/image", {name: success.data.id, type: "localities", data: this.image}).then(() => {
          this.$router.push({path: "/admin/program/place"})
        }).catch((error) => this.error = error)
      }).catch((error) => this.error = error)
    },
    load: function (id) {
      axios
          .get("/stage", {params: {id: id}})
          .then((success) => {
            this.stage = success.data;
            this.image = config.API_URL + "/image?type=localities&name=" + id
          }).catch((error) => this.error = error)
    },
    selectImage: function (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = e => {
        this.image = e.target.result
      }
    }
  }
}
</script>

<style scoped>
#placeEdit {
  background-color: white;
  min-height: 100vh;
}

</style>
